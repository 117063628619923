import InspectionResponse from './InspectionResponse.js';

export default class InspectionItem {
	constructor(dto) {
		this.id = 0;
		this.text = null;
		this.passingResponse = null;
		this.sortOrder = 0;

		if (typeof dto === 'object' && dto !== null) {
			if (typeof dto.id === 'number') {
				this.id = dto.id;
			}
			if (typeof dto.text === 'string') {
				this.text = dto.text;
			}
			if (typeof dto.passingResponse === 'number' && InspectionResponse.isValid(dto.passingResponse)) {
				this.passingResponse = dto.passingResponse;
			}
			if (typeof dto.sortOrder === 'number') {
				this.sortOrder = dto.sortOrder;
			}
		}
	}
}
